<template>
  <div class="faq">
    <div class="faq__list">
      <p class="content" v-html="content"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQPage",
  data: () => ({
    content: `1. If you are contacting Live Chat, immediately write about the case. So we can guarantee you that you will not be ignored.</br></br>
      2. All numbers that you use can be used as many times as you need.</br></br>
      3. We do not send SMS, we only receive them to our real numbers.</br></br>
      4. All prices in SMSPLAZA.io service mean the price for 1 SMS.</br></br>
      5. You have the option to buy only clean numbers that have not been used for your chosen service/site.</br></br>
      6. Balance is updated for 5-10 minutes. If your balance has not been updated in 10 minutes after the top-up, write to the Live Chat.</br></br>
      7. When you deposited via PayPal, your available only 2 deposits per day.</br></br>
      8. If you deposited via Cryptocurrency, you need to wait for 2 confirms and then wait 1-5 minutes, after that your balance will be updated.`
  }),
  methods: {
    item(index) {
      alert(index);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
</style>
